import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Player } from '@lottiefiles/react-lottie-player';
import { TestResponsePrivate } from '@tests/types';
import { Button, Col, Row, Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import { always, cond, inc, isNil, T } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';

import { Close, Loader } from '@/assets';
import {
  useCreateResultMutation,
  useDeleteResultMutation,
  useGetResultsListQuery,
} from '@/services';
import { getResultsMaxLength, isCrossword, isExtendedTest, isPoll, isQuiz, isTest } from '@/utils';

import { AnswerResultModal } from '../AnswerResultModal/AnswerResultModal';
import { Result } from './Result';
import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

const getResultTitle = cond([
  [isExtendedTest, always('Результат')],
  [T, always('Диапазон')],
]);

export const Results: React.FC<Props> = ({ test }) => {
  const { id, type } = test;

  const [activeTab, setActiveTab] = useState('index-0');
  const [isAdded, setIsAdded] = useState(false);
  const [isDeleted, setIsDeleted] = useState<string | false>(false);
  const [isLoadingCreateResult, setIsLoadingCreateResult] = useState(false);
  const [showModalResult, setShowModalResult] = useState(false);
  const [modalAnswersResult, setModalAnswersResult] = useState<number | null>(null);

  const {
    data: { results } = {},
    isLoading,
    isFetching,
  } = useGetResultsListQuery(
    { relations: ['media'], test: id },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  const [createResult] = useCreateResultMutation();
  const [deleteResult] = useDeleteResultMutation();

  const handleOpenModalAnswersResult = (id: number) => {
    setModalAnswersResult(id);
    setShowModalResult(true);
  };

  const handleCloseModalAnswersResult = () => {
    setShowModalResult(false);
  };

  const onTabClick = (key: string) => {
    setActiveTab(key);
  };

  const handleAddResult = useCallback(async () => {
    setIsLoadingCreateResult(true);
    await createResult({ testId: id });
    setIsLoadingCreateResult(false);
    setIsAdded(true);
  }, [createResult, id]);

  const handleChangeTabOnDelete = (index: number) => {
    if (activeTab === `index-${index}`) {
      if (!isNil(results[index + 1])) {
        setIsDeleted(`index-${index}`);
      } else {
        setIsDeleted(!isNil(results[index - 1]) ? `index-${index - 1}` : 'index-0');
      }
    } else {
      const [__, currentIndex] = activeTab.split('-');
      if (index > +currentIndex) {
        setIsDeleted(`index-${+currentIndex}`);
      } else {
        setIsDeleted(`index-${+currentIndex - 1 > 0 ? +currentIndex - 1 : 0}`);
      }
    }
  };

  useEffect(() => {
    if (isAdded) {
      setActiveTab(`index-${results?.length - 1}`);
      setIsAdded(false);
    }
  }, [results]);

  useEffect(() => {
    if (isDeleted) {
      setActiveTab(isDeleted);
      setIsDeleted(false);
    }
  }, [results]);

  const isDisabledCreateButton =
    isFetching || isLoadingCreateResult || results?.length >= getResultsMaxLength(type);

  const additionalTabButtons = {
    right: (
      <Button
        className={styles.additionalTab}
        onClick={handleAddResult}
        type="text"
        disabled={isDisabledCreateButton}
      >
        <Row
          align="middle"
          className={classNames(styles.additionalTab, styles.active, styles[type])}
        >
          <PlusOutlined />
        </Row>
        <Row
          align="middle"
          className={classNames(styles.additionalTab, styles.active, styles[type])}
        >
          <Col span={24}>{getResultTitle(type)}</Col>
        </Row>
      </Button>
    ),
  };

  if (!results) {
    if (isLoading) {
      return (
        <div className={styles.loaderWrapper}>
          <Player autoplay loop src={Loader} className={styles.loader} />
        </div>
      );
    }

    return <div>Нет результатов!</div>;
  }

  if (isCrossword(type)) {
    const result = results[0];

    return (
      <div className={styles.resultsWrapper}>
        <Result testId={id} result={result} index={0} key={`index-${result.id}`} testType={type} />
      </div>
    );
  }

  const items: TabsProps['items'] = results?.map((result, index) => ({
    children: (
      <Result
        testId={id}
        result={result}
        index={index}
        key={`index-${result.id}`}
        testType={type}
        onOpenModalAnswersResult={handleOpenModalAnswersResult}
      />
    ),
    key: `index-${index}`,
    label: (
      <Row gutter={[5, 5]} wrap={false}>
        <Col
          className={classNames(
            styles.additionalTab,
            activeTab === `index-${index}` && styles.active,
            styles[type],
          )}
        >
          {`${getResultTitle(type)} №${inc(index)}`}
          {isExtendedTest(type) && (
            <span style={{ backgroundColor: result.color }} className={styles.resultBadge}>
              P{inc(index)}
            </span>
          )}
        </Col>
        {results.length > 2 && (
          <Close
            onClick={async (e: any) => {
              e.stopPropagation();
              await deleteResult(result.id);
              handleChangeTabOnDelete(index);
            }}
            className={classNames(
              styles.close,
              activeTab === `index-${index}` && styles.active,
              styles[type],
            )}
          />
        )}
      </Row>
    ),
  }));

  return (
    <div className={styles.resultsWrapper}>
      <Tabs
        onTabClick={onTabClick}
        tabBarExtraContent={results.length < getResultsMaxLength(type) && additionalTabButtons}
        className={classNames(
          styles.tabs,
          isTest(type) && styles.testTabs,
          isPoll(type) && styles.pollTabs,
          isQuiz(type) && styles.quizTabs,
        )}
        activeKey={activeTab}
        defaultActiveKey="index-0"
        items={items}
        moreIcon={<EllipsisOutlined className={styles.moreTabs} />}
        popupClassName={styles.popup}
      />
      {showModalResult && (
        <AnswerResultModal
          results={results.filter((result) => result.id === modalAnswersResult)}
          testId={test.id}
          onClose={handleCloseModalAnswersResult}
        />
      )}
    </div>
  );
};
